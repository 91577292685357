@import 'nib'
@import 's-grid-settings'
@import 's-grid-functions'
@import 's-grid-classes'

@keyframes fadein {
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

global-reset()

html
	box-sizing border-box
	font-weight 100

*, *:before, *:after
	box-sizing inherit

body
	//background linear-gradient(135deg, #9cdbf3 0%,#53cbf1 50%,#52bddd 100%)
	animation fadein 2s
	font-family 'Droid Serif', serif
	font-size 18px
	text-rendering optimizeLegibility
	-webkit-font-smoothing antialiased

/*
 * Typography
 */
h1
	font-size 2em
	font-weight 200

h2
	font-size 1.5em
	font-weight 200

h3
	font-size 1.17em

h4
	font-size 1.12em

h5
	font-size .83em

h6
	font-size .75em

p
	line-height 1.7rem

	a
		border-bottom 3px solid #1fc4f4
		display inline
		color black
		text-decoration none

		&:hover
			border-bottom-color transparent

.wrapper
	margin 0 auto
	max-width 700px
	width 90%

/*
 * home
 */
.home
	margin-top -100px

	+mobile()
		margin-top 0

	h1
		img
			display block
			margin 0 auto

			+mobile()
				width 100%

		span
			visibility hidden

	p
		text-align justify


/*
 * Contact
 */
.contact
	margin -100px auto 0
	max-width 500px

	+mobile()
		margin-top 0

	iframe
		height 100vh

	h2
		margin 1.5rem 0

	form
		width 100%

		.input-control
			margin 0 0 1.3rem
			position relative

			label, input, textarea
				display block
				font-size 1rem
				width 100%

			label
				margin-bottom 0.5rem

			div.error
				color red
				font-size 1rem
				position absolute
				right 0
				top 0

	.form-response-message
		display none
		text-align center

		img
			margin-bottom 1rem

/*
 * Single page
 */
.single-page
	align-items center
	display flex
	justify-content center
	height 100vh

	h1
		text-align center

		img
			display block
			margin 0 auto 1rem

/*
 * Form Elements
 */
input[type='text'], input[type='email'], textarea
	border 1px solid black
	font-family 'Droid Serif', serif
	padding 0.5rem

button
	background-color white
	border 1px solid black
	border-radius 0
	cursor pointer
	font-family 'Droid Serif', serif
	font-size 1rem
	padding 0.5rem 1rem

	+mobile()
		width 100%